import Immutable from 'seamless-immutable';

import { AUTH_SUCCESS } from 'containers/Auth/actions';
import { selectRoles, selectUser } from 'containers/Auth/selectors';

export const LOCATION_CHANGED = 'events/LOCATION_CHANGED';

function pageView(action) {
  return {
    hitType: 'pageview',
    page: action.payload,
  };
}

function trackSuccessfulAuthentication(action, prevState, nextState) {
  const roleOrder = ['live', 'premium', 'editor', 'admin'];
  const getRole = (user, roles) =>
    user == null
      ? 'Logged out' // Return 'Logged out' when we don't have a user
      : roles.length === 0 // Return 'Logged in: none' for users without any special privileges
        ? 'Logged in: none'
        : `Logged in: ${
            Immutable.asMutable(roles).sort(
              (a, b) => roleOrder.indexOf(b) - roleOrder.indexOf(a),
            )[0]
          }`; // Return the role with most privileges

  return {
    event: 'visitorStateChange',
    visitorState: getRole(selectUser(nextState), selectRoles(nextState)),
    email: selectUser(nextState)?.email,
  };
}

const eventsMap = {
  [LOCATION_CHANGED]: pageView,
  [AUTH_SUCCESS]: trackSuccessfulAuthentication,
};

export default eventsMap;
