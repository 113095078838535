import formatDateLib from 'date-fns/format';
import formatRelative from 'date-fns/formatRelative';
import styled from 'styled-components';

import svLocale from './svDateLocale';

const NoWrap = styled.span`
  white-space: nowrap;
`;

export const round = value => Math.round(value, 10);

export const odds = value => value && Math.round(value / 10, 10) / 10;

export const percent = value => `${round(value * 100)} %`;

export const formatNumber = x =>
  round(x)
    .toString()
    .replaceAll(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const number = x => x && <NoWrap>{formatNumber(x)}</NoWrap>;

export const money = (x, isPennies = false) =>
  x && <NoWrap>{formatNumber(isPennies ? x / 100 : x)} kr</NoWrap>;

/**
 * Format a Date into a string.
 *
 * Formatting docs: https://date-fns.org/docs/format
 */
export function formatDate(date, format = "d MMMM, yyyy 'kl.' HH:mm") {
  return formatDateLib(date, format, { locale: svLocale });
}

/**
 * Format a Date into words relative to now.
 *
 * Docs: https://date-fns.org/docs/formatRelative
 * See also utils/svDateLocale.js
 */
export function formatDateRelative(
  date,
  { compact = false, timePrefix = true },
) {
  const s = formatRelative(date, new Date(), {
    locale: svLocale,
    compact,
  });
  return !timePrefix ? s.replace('kl. ', '') : s;
}

/*
 * Format round
 * Values: bet_type trackname & date
 * Ex: V75 Östersund 29/01
 */
export function formatRound(round) {
  return `${round.bet_type} ${round.track_name} ${formatDate(
    new Date(round.round_date),
    'dd/MM',
  )}`;
}
