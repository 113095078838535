export class SocketClient {
  constructor(uri) {
    // Print logging
    this.debug = false;

    this.wsURI = uri;

    this.listeners = {};
    this.queue = [];

    this._reconnectTimeoutID = null;
    this.reconnectTimeout = 15_000;

    this.bundleSubscriptions = true;
    this.bundleTimeout = 1;
    this._bundleTimeoutID = null;

    this._connect();
  }

  isConnected() {
    return this.ws.readyState === WebSocket.OPEN;
  }

  send(message) {
    if (!this.isConnected()) {
      this._log('Not connected. Putting message in queue.');
      this.queue.push(message);
      return;
    }

    this._send(message.toString());
  }

  emit(event, data) {
    this.send(`${event}|${JSON.stringify(data)}`);
  }

  _connect(_reconnecting) {
    this._log('socker connecting to', this.wsURI);

    this.ws = new WebSocket(this.wsURI);

    this.ws.addEventListener(
      'open',
      function () {
        if (this._reconnectTimeoutID) {
          clearTimeout(this._reconnectTimeoutID);
        }
        this._reconnectTimeoutID = null;

        this._log('Connected');

        if (_reconnecting) {
          this._subscribeAll();
        }

        this._sendAll();
      }.bind(this),
    );

    this.ws.addEventListener('message', this._onMessage);

    this.ws.addEventListener('close', this._onClose);
  }

  subscribe(name) {
    if (!this.listeners.hasOwnProperty(name)) {
      this.listeners[name] = true;
      this._subscribeAll();
    }
  }

  ussubscribe(name) {
    if (this.listeners.hasOwnProperty(name)) {
      delete this.listeners[name];
    }
  }

  message(callback) {
    this.callback = callback;
  }

  _onMessage = e => {
    try {
      const message = JSON.parse(e.data);

      if (this.hasOwnProperty('callback')) {
        this.callback(message, e);
      }
    } catch (error) {
      console.error(error);
    }
  };

  _onClose = () => {
    this._log('Connection closed');
    this._closed = true;

    this._log(`Reconnecting in ${this.reconnectTimeout / 1000} seconds.`);

    this._reconnectTimeoutID = setTimeout(
      () => this._reconnect(),
      this.reconnectTimeout,
    );
  };

  _reconnect = () => {
    this._log('reconnecting...');
    this._connect(true); // Indicate to _connect that we are reconnecting.
  };

  _sendAll() {
    this.queue.forEach(
      function (message) {
        this._send(message.toString());
      }.bind(this),
    );
  }

  _send(string) {
    this._log(`sock >> ${string}`);
    this.ws.send(string);
  }

  _subscribeAll() {
    const channels = Object.keys(this.listeners);

    if (this.bundleSubscriptions) {
      if (this._bundleTimeoutID) {
        clearTimeout(this._bundleTimeoutID);
      }
      this._log('Bundling subscriptions');

      this._bundleTimeoutID = setTimeout(() => {
        this._subscribe(channels);
      }, this.bundleTimeout);

      return;
    }

    this._subscribe(channels);
  }

  _subscribe(channels) {
    this.emit('set-subscriptions', channels);
  }

  _log(...args) {
    if (this.debug) {
      console.info(...args);
    }
  }

  toString() {
    let status = 'UNKNOWN';
    const stateMap = {
      0: 'CONNECTING',
      1: 'OPEN',
      2: 'CLOSING',
      3: 'CLOSED',
    };

    if (this.ws) {
      status = stateMap[this.ws.readyState];
    }

    return `<Socker uri=${this.wsURI} status=${status}>`;
  }
}
